import React, { useEffect, useState} from 'react';
// @ts-ignore
import * as tj from '@mapbox/togeojson';
// @ts-ignore
import * as tokml from 'tokml';
import {DOMParser} from 'xmldom'
import './App.css';
import {Box, Button, Heading, Spinner, Stack, Text, useToast} from "@chakra-ui/react";
import {useDropzone} from "react-dropzone";

function App() {
    const [loading, setLoading] = useState(false);
    const [objectUrl, setObjectUrl] = useState<string | undefined>();
    const [showDownload, setShowDownload] = useState(false);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
        accept: {'application/gpx+xml': ['.gpx']}
    });
    const toast = useToast()

    useEffect(() => {
        setShowDownload(false);
    }, [acceptedFiles])

    useEffect(() => {
        if (fileRejections.length > 0) {
            toastError("Only select 1 file")
        }
    }, [fileRejections])

    function toastError(title: string) {
        toast({
            title: title, status: 'error', duration: 5000
        })
    }

    function fillObjectUrl(kml: string) {
        if (objectUrl) {
            setObjectUrl(undefined);
            URL.revokeObjectURL(objectUrl);
        }

        let blob = new Blob([kml], {type: 'application/octet-stream'},);
        setObjectUrl(URL.createObjectURL(blob));
    }

    function convertGpxToKml(gpx: string): string {
        let gpxDom = new DOMParser().parseFromString(gpx);
        let geoJson = tj.gpx(gpxDom);
        return tokml(geoJson);
    }

    async function convertPressed() {
        let gpxFile = acceptedFiles.at(0);
        if (!gpxFile) {
            toastError("No file selected")
            return;
        }

        setLoading(true);
        try {
            let gpxString = await gpxFile.text();
            let kmlString = convertGpxToKml(gpxString);
            fillObjectUrl(kmlString);
            setShowDownload(true);
        } catch (e) {
            toastError("Error converting gpx file")
        }
        setLoading(false);
    }


    return (
        <div style={{width: "80%", margin: "auto", marginTop: "20px"}}>
            <Stack align={'center'} mx={'auto'} py={12} spacing={10}>
                <Heading fontSize={'7vw'} color={"white"}>GPX TO KML</Heading>
                <Box padding={"40px"} width={"90%"} borderWidth='1px' borderRadius='lg' cursor={"pointer"}
                     backgroundColor={"rgba(0, 0, 0, 0.1)"} borderStyle={"dashed"}
                     overflow='hidden'{...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <Text color={"white"}>Drag 'n' drop file here, or click to select file</Text>
                    <aside>
                        {acceptedFiles.map(file => (
                        <Text color={"white"} key={file.name}>
                            {file.name} - {file.size} bytes
                        </Text>))}
                    </aside>
                </Box>
                {loading && <Spinner color={"white"}/>}
                <Stack spacing={4} direction='row' align='center'>
                {acceptedFiles.length > 0 && !loading && <Button onClick={convertPressed}>Convert</Button>}
                {showDownload && !loading && objectUrl &&
                    <Button><a href={objectUrl} target={"_blank"} download={"export.kml"} rel="noreferrer">Download</a></Button>}
                </Stack>
            </Stack>
            <Text textAlign={"center"} color={"white"} left={0} bottom={0} position={"fixed"} width={"100%"} mb={5} textDecoration={"italic"}>Created by: Derek Meuwissen <a href={"mailto:derekmeuwissen@gmail.com"}>(derekmeuwissen@gmail.com)</a></Text>
        </div>
    );
}

export default App;
